import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../mixins';

export default {
    name: "CourseIndex",
    mixins: [myMixin],
    data: () => ({
        collections: [],
        params: {},
        message: '',
        error: false,
        loading: false,
        meta: {},
        itemDelete: {},
        model: {},
        categories: [],
        show: false,
        showForm: false,
        text: '',
        deleting: false,
        headers: [
            { text: 'Código', value: 'code'},
            { text: 'Nome', value: 'name'},
            { text: 'Grau Acadêmico', value: 'degree.name'},
            { text: 'Modalidade', value: 'modality.name'},
            { text: 'Sigla', value: 'initials'},
            { text: 'Área', value: 'occupation_area.name'},
            { text: 'Status', value: 'active'},
            { text: 'Ações', value: 'actions', sortable: false}
        ]
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    created() {
       
    },
    methods: {
        ...mapActions('course', ['ActionFindCourses', 'ActionDeleteCourse']),

        getData() {
            this.showForm = false;
            this.error = false;
            this.message = '';
            this.loading = true;
            const params = this.params;
                        
            Object.assign(params, {
                institution_id: this.acesso.instituicao.id,
                with: 'degree,modality'
            })
            this.ActionFindCourses(params)
                .then((res) => {
                    this.collections = res.data
                    this.meta = res.meta
                })
                .finally(() => {
                    this.loading = false
                })
        },

        deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteCourse({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true
                })
                .finally(() => {
                    this.deleting = false
                })
        }
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + item_new.name + "?"
            } else {
                this.show = false
                this.text = ''
            }
        },
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },
        model(item_new, item_old){
            if(item_new !== item_old && this.model.id)
            {
                this.titleForm = 'Editar curso'
                this.showForm = true
            } else if(Object.keys(this.model).length === 0){
                this.titleForm = 'Cadastrar curso'
                this.showForm = false
            }           
        },
        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },
    },
    
}